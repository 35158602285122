<template>
  <div>
    <p class="title is-4">Products Updater for price monitor</p>
    <p class="subtitle is-6">Retrieve <i>model, name, brand, productClass, nsSku, nsIdIndex, homeDepotItemId, wayfairSku, wayfairOptionName, wayfairOptionNameTwo, overstockSku, overstockOptionId, amazonAsin, lowesItemId, wholesaleModel</i> columns from CSV file to update products, deduped by the <i>model</i> column.</p>

    <div class="upload-block">
      <div class="field has-addons">
        <div class="control">
          <div class="file has-name">
            <label class="file-label">
              <input class="file-input" type="file" accept=".csv" @change="onFileChange">
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-upload"></i>
                </span>
              </span>
              <span class="file-name">
                {{file ? file.name : 'Choose a CSV file...'}}
              </span>
            </label>
          </div>
        </div>
        <div class="control">
          <button class="button" :class="{'is-loading': waiting}" :disabled="!file" @click="updateProducts">
            Update Products
          </button>
        </div>
      </div>
      <div v-if="error" class="notification is-danger is-light">
        <button class="delete" @click="error=''"></button>
        {{error}}
      </div>
      <div v-if="success" class="notification is-success is-light">
        <button class="delete" @click="success=''"></button>
        <div><strong>{{success}}</strong></div>
        <br/>
        <div>
          <strong>Updated successfully: {{successes.length}}</strong><br/>
          <ul>
            <li v-for="(s, i) in successes" :key="'s-'+i">{{s}}</li>
          </ul>
        </div>
        <br/>
        <div>
          <strong>Model NOT found: {{failures.length}}</strong><br/>
          <ul>
            <li v-for="(f, i) in failures" :key="'f-'+i">{{f}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { parse } from 'csv-parse/lib/sync'

export default {
  name: 'product-updater',
  data () {
    return {
      file: null,
      waiting: false,
      error: '',
      success: '',
      successes: [],
      failures: [],
      fieldNames: ['name', 'brand', 'model', 'productClass', 'nsSku', 'nsIdIndex', 'homeDepotItemId', 'wayfairSku', 'wayfairOptionName', 'wayfairOptionNameTwo', 'overstockSku', 'overstockOptionId', 'amazonAsin', 'lowesItemId', 'wholesaleModel']
    }
  },
  computed: {
    server () {
      return this.$store.state.config.server
    },
  },
  methods: {
    onFileChange (e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.file = files[0];
    },
    updateProducts () {
      if (!this.file || this.waiting) {
        return
      }
      this.waiting = true
      this.error = ''

      var vm = this
      var reader = new FileReader()
      reader.onload = function () {
        var text = reader.result
        var records = parse(text, {
          columns: true,
        })
        vm.sendUpdates(records)
      }
      reader.readAsText(this.file)
      
    },
    sendUpdates (records) {
      var batches = []
      var batch = []
      for (var record of records) {
        if (record['model']) {
          var update = {}
          for (const fieldName of this.fieldNames) {
            if (record[fieldName]) {
              update[fieldName] = record[fieldName].trim()
            }
          }
          batch.push(update)
          if (batch.length == 100) {
            batches.push(batch.slice())
            batch = []
          }
        }
      }
      if (batch.length) {
        batches.push(batch)
      }

      var promises = []
      this.successes = []
      this.failures = []
      this.success = ''
      this.error = ''
      for (var updates of batches) {
        var message = {updates: updates}
        var promise = this.$http.post(this.server + '/myapp/batch-update-product-by-model/', message).then(resp => {
          this.successes = this.successes.slice().concat(resp.body.successes)
          this.failures = this.failures.slice().concat(resp.body.failures)
        }, err => {
          console.log('One update request failed', err)
        })
        promises.push(promise)
      }

      Promise.all(promises).then(resp => {
        this.success = 'Updates have been finished.'
        this.waiting = false
      }, err => {
        this.error = 'Some updates failed.'
        this.waiting = false
      })
    },
  },
}
</script>
