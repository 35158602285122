<template>
  <div class="container">
    <article class="message is-danger" v-if="!superuser">
      <div class="message-body">
        You have not signed in yet. Please go back to <a href="/">home page</a> to sign in first.
      </div>
    </article>

    <section class="section" v-if="superuser">
      <h1 class="title is-4">Config</h1>

      <div class="field">
        <label class="label">OOS Monitor Alerts</label>
        <div class="field has-addons" v-for="(receiver, i) in oosMonitor" :key="'oos-monitor-' + i">
          <p class="control has-icons-left">
            <span class="select">
              <select v-model="receiver.email">
                <option v-for="(email, j) in emails" :key="'oos-monitor-' + i + '-email-option-' + j">
                  {{email}}
                </option>
              </select>
              <span class="icon is-left">
                <i class="fas fa-envelope"></i>
              </span>
            </span>
          </p>
          <p class="control">
            <a class="button" @click="removeOOSMonitorEmail(i)">
              <span class="icon is-small">
                <i class="fas fa-minus"></i>
              </span>
            </a>
          </p>
        </div>

        <div class="field has-addons">
          <div class="control has-icons-left">
            <span class="select">
              <select v-model="newOOSMonitorEmail">
                <option v-for="(email, i) in emails" :key="'approval-receiver-email-option-' + i">
                  {{email}}
                </option>
              </select>
              <span class="icon is-left">
                <i class="fas fa-envelope"></i>
              </span>
            </span>
          </div>
          <div class="control">
            <a class="button is-success" @click="addOOSMonitorEmail()">
              <span class="icon is-small">
                <i class="fas fa-plus"></i>
              </span>
            </a>
          </div>
        </div>
      </div>

      <div v-if="false" class="field">
        <label class="label">Reviewer Mappings</label>
        <div class="field has-addons" v-for="(mapping, i) in reviewMappings" :key="'reviewer-mapping-' + i">
          <p class="control has-icons-left">
            <input class="input" type="text" placeholder="Key" v-model="mapping.key">
            <span class="icon is-left">
              <i class="fas fa-key"></i>
            </span>
          </p>
          <p class="control has-icons-left">
            <span class="select">
              <select v-model="mapping.email">
                <option v-for="(email, j) in emails" :key="'reviewer-mapping-' + i + '-email-option-' + j">
                  {{email}}
                </option>
              </select>
            </span>
            <span class="icon is-left">
              <i class="fas fa-envelope"></i>
            </span>
          </p>
          <p class="control">
            <a class="button" @click="removeReviewerMapping(i)">
              <span class="icon is-small">
                <i class="fas fa-minus"></i>
              </span>
            </a>
          </p>
        </div>
        <div class="field has-addons">
          <p class="control has-icons-left">
            <input class="input" type="text" placeholder="Key" v-model="newReviewerMapping.key">
            <span class="icon is-left">
              <i class="fas fa-key"></i>
            </span>
          </p>
          <p class="control has-icons-left">
            <span class="select">
              <select v-model="newReviewerMapping.email">
                <option v-for="(email, i) in emails" :key="'new-reviewer-mapping-email-option-' + i">
                  {{email}}
                </option>
              </select>
            </span>
            <span class="icon is-left">
              <i class="fas fa-envelope"></i>
            </span>
          </p>
          <p class="control">
            <a class="button is-success" @click="addReviewerMapping">
              <span class="icon is-small">
                <i class="fas fa-plus"></i>
              </span>
            </a>
          </p>
        </div>
      </div>

      <div class="field">
        <label class="label">USD to CAD</label>
        <div class="control">
          <input class="input" type="number" v-model.number="usdToCad">
        </div>
      </div>

      <div class="field is-grouped mt-4">
        <div class="control">
          <button class="button is-link" :disabled="!myConfigChanged" :class="{'is-loading': waiting}" @click="updateMyConfig">
            Save
          </button>
        </div>
      </div>
      
      <div v-if="myConfigChanged" class="notification is-warning is-light">
        You have unsaved changes!
      </div>

      <hr />

      <product-updater />

      <hr />

      <h1 class="title is-4">Compute sale speed</h1>

      <div class="field mt-5">
        <label class="label">Sale Speed</label>
      </div>
      <div class="field has-addons">
        <p class="control is-expanded">
          <input v-model="saleSpeedProductId" class="input" type="text" placeholder="Product Id">
        </p>
        <p class="control">
          <button class="button is-link" @click="computeSaleSpeed">
            Compute
          </button>
        </p>
      </div>

      <hr />
      <h1 class="title is-4">NetSuite callers</h1>

      <div class="field mt-5">
        <label class="label">Get Resource</label>
      </div>
      <div class="field has-addons">
        <p class="control is-expanded">
          <input v-model="netsuitePath" class="input" type="text" placeholder="Resource Path">
        </p>
        <p class="control">
          <button class="button is-link" @click="testNetsuite">
            Get Resource
          </button>
        </p>
      </div>

      <div class="field mt-5">
        <label class="label">Call Restlet</label>
      </div>
      <div class="field has-addons">
        <p class="control">
          <input v-model="restletScript" class="input" type="text" placeholder="Script">
        </p>
        <p class="control">
          <input v-model="restletDeploy" class="input" type="text" placeholder="Deploy">
        </p>
        <p class="control is-expanded">
          <input v-model="restletParams" class="input" type="text" placeholder="Params in json">
        </p>
        <p class="control">
          <button class="button is-link" @click="callNetsuiteRestlet">
            Call Restlet
          </button>
        </p>
      </div>

    </section>
  </div>
</template>

<script>
import ProductUpdater from '@/components/price-monitor/ProductUpdater'

export default {
  name: 'MyConfig',
  components: {
    ProductUpdater
  },
  data () {
    return {
      waiting: false,
      error: '',
      myConfig: null,
      oosMonitor: [],
      newOOSMonitorEmail: '',
      newApprovalReceiver: '',
      reviewMappings:[{key: 'NY', email: 'jianghengle@gmail.com'}], // to be initialzied, value here to demo the UI
      newReviewerMapping: {key:'', email: ''},
      emails: [],
      netsuitePath: '',
      restletScript: '',
      restletDeploy: '',
      restletParams: '',
      saleSpeedProductId: '',
      usdToCad: 1.36,
    }
  },
  computed: {
    server () {
      return this.$store.state.config.server
    },
    superuser () {
      return this.$store.state.user.superuser
    },
    canAddApprovalReceiver () {
      return true
    },
    canAddReviewerMapping () {
      return true
    },
    myConfigChanged () {
      if (!this.myConfig) {
        return false
      }
      var myConfig = this.buildMyConfig()
      return JSON.stringify(this.myConfig) != JSON.stringify(myConfig)
    },
  },
  methods: {
    getMyConfig () {
      this.waiting = true
      this.$http.get(this.server + '/myapp/get-my-config/').then(resp => {
        this.myConfig = resp.body
        this.oosMonitor = resp.body.emailSettings.oosMonitor.map(e => ({email: e}))
        this.usdToCad = resp.body.exchangeRates && resp.body.exchangeRates.CAD
        this.waiting = false
      }, err => {
        this.error = err.body
        this.waiting = false
      })
    },
    getEmails () {
      this.$http.get(this.server + '/myapp/get-all-emails/').then(resp => {
        this.emails = resp.body
      }, err => {
        this.error = err.body
      })
    },
    buildMyConfig () {
      return {
        emailSettings: {
          oosMonitor: this.oosMonitor.map(e => e.email)
        },
        exchangeRates: {
          CAD: this.usdToCad
        },
      }
    },
    updateMyConfig () {
      // construct myConfig object from approveReceivers and reviewerMappings
      // post to /myapp/get-my-config/ to update the config
      var message = this.buildMyConfig()
      this.waiting = true
      this.$http.post(this.server + '/myapp/update-my-config/', message).then(resp => {
        console.log('update my config: ', resp.body)
        this.myConfig = resp.body
        this.oosMonitor = resp.body.emailSettings.oosMonitor.map(e => ({email: e}))
        this.waiting = false
      }, err => {
        this.error = err.body
        this.waiting = false
      })
    },
    addOOSMonitorEmail () {
      if (this.newOOSMonitorEmail) {
        this.oosMonitor.push({email: this.newOOSMonitorEmail})
        this.newOOSMonitorEmail = ''
      }
    },
    removeOOSMonitorEmail (i) {
      this.oosMonitor.splice(i, 1)
    },
    addReviewerMapping () {
      console.log('No implementation yet')
    },
    removeReviewerMapping (i) {
      console.log('No implementation yet')
    },
    testNetsuite () {
      this.$http.post(this.server + '/myapp/test-netsuite/', {path: this.netsuitePath}).then(resp => {
        console.log('NS resource: ', resp.body)
      })
    },
    callNetsuiteRestlet () {
      var data = {
        script: this.restletScript.trim(),
        deploy: this.restletDeploy.trim(),
        params: this.restletParams.trim(),
      }
      this.$http.post(this.server + '/myapp/call-netsuite-restlet/', data).then(resp => {
        console.log('Restlet resp', resp.body)
      })
    },
    computeSaleSpeed () {
      var data = {productId: this.saleSpeedProductId}
      this.$http.post(this.server + '/myapp/compute-sale-speed-for-product/', data)
    },
  },
  mounted () {
    if (this.superuser) {
      this.getMyConfig()
      this.getEmails()
    }
  }
}
</script>


